import ComponentRepository from './architecture/componentRepository';


document.addEventListener('DOMContentLoaded', function () {
    // Define all components here.
    // The property name is the key used to instantiate the component.
    // The property value is the path of the file (navigated from the scripts folder) excluded the file ending.
    // The order they appear in the sourcemap will affect the order they are instantiated in.
    // Shared components should generally be instantiated before controllers.
    const componentSourceMap = {
        'top-navigation': () => import('./components/top-navigation'),
        /*'my-test-component': () => import('./components/my-test-component'),*/
        /*'example-controller': () => import('./controllers/example-controller'),*/
        'tab-section': () => import('./components/tab-section'),
        'dropdown': () => import('./components/dropdown'),
        'slider-section-slider': () => import('./components/slider-section-slider'),
        'image-slider': () => import('./components/image-slider'),
        'overview-controller': () => import('./components/overview-controller'),
        'search-page': () => import('./components/search-page'),
        'media-with-video': () => import('./components/media-with-video'),
        'slideshow': () => import('./components/slideshow'),
        'email-validation': () => import('./components/email-validation'),
    };

    // Instantiate the component repository - This will ensure that all components needed for the current page are loaded.
    let cr;
    window.cr = cr = new ComponentRepository(componentSourceMap);

    //******** BELOW is only to demonstrate functions from the ComponentRepository and will typically not be called here in the index file.

    // Get the instance by the component-id 'main-nav'
    /*cr.getGlobalInstance('main-nav')
        .then(mainNavInstance => {
            console.log('main-nav instance', mainNavInstance);
        });*/

    // Get all instances of the component type 'my-test-component'
    /*cr.getInstancesOfType('my-test-component')
        .then(myTestComponentInstances => {
            console.log('here are all instances of "my-test-component" class', myTestComponentInstances);
        });*/

    // Get the class by the type 'my-test-component'
    // This function can be used to instantiate components for markup that is dynamically loaded without the need to know what is loaded.
    /*cr.loadComponentClass('my-test-component')
        .then(ComponentClass => {
            console.log('here is a referencee to "my-test-component" class', ComponentClass);
        });*/
});
